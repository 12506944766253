/**
 * Bootstrap Reset
 */

@import "../Common/Variables";
@import "../Common/Mixins";

//
// Use this file to reset simple bootstrap styles
// instead of modifying the core files
// Makes easy to update new framework versions
// --------------------------------------------------

*:focus {
  outline: 0 !important;
}

select,
input,
textarea {
  color: inherit;
  font: inherit;
}

a,
.btn-link {
  outline: none !important;
  color: $link-color;
  transition: color 0.2s ease;
  // &:focus, &:hover {
  //     color: darken($link-color, 6%);
  //     cursor: pointer;
  // }
  &.text-muted {
    &:hover,
    &:focus {
      color: darken($text-muted, 10%) !important;
    }
  }
}

address {
  margin-bottom: 2rem;
}

small {
  font-size: 85%;
}

.rounded {
  border-radius: $border-radius-large !important;
}

.text-muted {
  color: $text-muted !important;
}

hr {
  border-top: 1px solid rgba($gray-base, 0.36);
  margin-top: 2rem;
  margin-bottom: 2rem;
}

code {
  margin-left: 1px;
  margin-right: 1px;
  -webkit-font-smoothing: auto;
  padding: 0.125em 0.35em;
  border-radius: 2px;
  font-size: inherit;
  background: rgba(0, 0, 0, 0.045);
  color: #106cc8;
}

pre {
  background-color: rgba($gray-base, 0.12);
  border-color: rgba($gray-base, 0.26);
  color: inherit;
}

.img-thumbnail {
  border-radius: 1px;
  border: 0;
  box-shadow: $material-shadow-z2;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid rgba($gray-base, 0.06);
}

// Breadcrumb
// -----------------------------------

.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: $text-muted;
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid rgba($gray-base, 0.16);
  margin-bottom: 2rem;
  .breadcrumb-item::before {
    color: #ccc;
  }
}

// Buttons
// -----------------------------------

.btn {
  outline: none !important;
  border-radius: 2px;
  transition-property: background-color, color, box-shadow;
  transition-duration: 0.1s, 0.2s, 0.2s;
  transition-timing-function: ease-in-out;
  font-weight: 400;
  line-height: 1.25rem;
  font-family: inherit;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  &:active,
  &.active {
    box-shadow: 0 0 0 #000;
  }
}

.btn-group-sm > .btn,
.btn-sm {
  @include button-size(0.3125rem, 0.625rem, 0.75rem, 0.25rem, 0.2rem);
  line-height: 1.5;
}

.btn-lg {
  @include button-size(0.625rem, 1rem, 1.125rem, 1.3, 0.2rem);
  line-height: 1.34;
}
// bootstrap 4 removed this
.btn-xs {
  @include button-size(1px, 5px, 12px, 1.5, 2px);
  line-height: 1.5;
}

.btn-purple {
  @include button-variant(#fff, #7c3088, #7c3088);
}
.btn-blue {
  @include button-variant(#fff, $brand-blue, $brand-blue);
}
// .btn-blue   { @include button-variant(#fff, #7c3088,$brand-blue); }
.btn-orange {
  @include button-variant(#fff, $brand-orange, $brand-orange);
}
.btn-primary {
  @include button-variant(#fff, $brand-primary, $brand-primary);
}
.btn-secondary {
  @include button-variant($text-color, #fff, $gray-lighter);
}
.btn-success {
  @include button-variant(#fff, $brand-success, $brand-success);
}
.btn-info {
  @include button-variant(#fff, $brand-info, $brand-info);
}
.btn-warning {
  @include button-variant(#fff, $brand-warning, $brand-warning);
}
.btn-danger {
  @include button-variant(#fff, $brand-danger, $brand-danger);
}
.btn-red {
  @include button-variant(#fff, #EC1A59, #EC1A59);
}

.btn-warning {
  &:hover {
    color: #fff;
  }
}

.btn-outline-secondary {
  @include button-outline-variant($text-color, $text-color, #ccd4dc, #ccd4dc);
  border-color: #ccd4dc;
}

// Emphasis
// -----------------------------------

.text-primary {
  color: $brand-primary !important;
}
.text-success {
  color: $brand-success !important;
}
.text-info {
  color: $brand-info !important;
}
.text-warning {
  color: $brand-warning !important;
}
.text-danger {
  color: $brand-danger !important;
}

// Pagination
// -----------------------------------

.pagination {
  > .page-item > .page-link {
    color: inherit;
    background-color: rgba(240, 240, 240, 0.06);
    border-color: rgba($gray-base, 0.16);
  }
  > .page-item > .page-link:focus,
  > .page-item > .page-link:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: #fff;
  }
  > .active > .page-link,
  > .active > .page-link:focus,
  > .active > .page-link:hover {
    background-color: $brand-primary;
    border-color: $brand-primary;
    color: #fff;
  }
  > .disabled {
    cursor: not-allowed;
    > .page-link,
    > .page-link:hover,
    > .page-link:focus {
      color: #777;
      background-color: rgba(240, 240, 240, 0.06);
      border-color: rgba($gray-base, 0.16);
    }
  }
}

// Tags
// -----------------------------------

.tag {
  display: inline;
  padding: 2px 6px 3px;
  font-size: 0.625rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
// .tag-default {
// }
.tag-primary {
  background-color: $brand-primary;
}
.tag-info {
  background-color: $brand-info;
}
.tag-success {
  background-color: $brand-success;
}
.tag-warning {
  background-color: $brand-warning;
}
.tag-danger {
  background-color: $brand-danger;
}

// Alerts
// -----------------------------------

.alert-primary {
  @include alert-variant($brand-primary, $brand-primary, #fff);
  a,
  .alert-link {
    color: rgba(255, 255, 255, 0.58);
  }
}
.alert-success {
  @include alert-variant($brand-success, $brand-success, #fff);
  a,
  .alert-link {
    color: rgba(255, 255, 255, 0.58);
  }
}
.alert-info {
  @include alert-variant($brand-info, $brand-info, #fff);
  a,
  .alert-link {
    color: rgba(255, 255, 255, 0.58);
  }
}
.alert-warning {
  @include alert-variant($brand-warning, $brand-warning, #fff);
  a,
  .alert-link {
    color: rgba(255, 255, 255, 0.58);
  }
}
.alert-danger {
  @include alert-variant($brand-danger, $brand-danger, #fff);
  a,
  .alert-link {
    color: rgba(255, 255, 255, 0.58);
  }
}

.alert-dismissable .close,
.alert-dismissible .close {
  color: rgba(0, 0, 0, 0.75);
}

// List
// -----------------------------------

.list-group {
  .list-group-item {
    color: inherit;
    background-color: transparent;
    border-color: rgba($gray-base, 0.12);
    padding: 1rem 1.5rem;
    &.active {
      color: #fff;
    }
    &.disabled,
    &.disabled:focus,
    &.disabled:hover {
      background-color: rgba($gray-base, 0.12);
      color: inherit;
    }
    .card > & {
      border-top: 0;
    }
  }
}
a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  background-color: transparent;
  color: $brand-primary;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  background-color: $brand-info;
  border-color: $brand-info;
}

// Cards
// -----------------------------------

.card {
  border-radius: 3px;
  border: 1px solid rgba($gray-base, 0.16);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  .card-header {
    background: transparent;
    padding: 16px 24px;
    font-weight: 500;
    border-bottom: 1px solid rgba($gray-base, 0.16);
  }
  .card-body {
    padding: 1.5rem;
  }
  .card-footer {
    background-color: rgba($gray-base, 0.1);
    border-color: rgba($gray-base, 0.1);
  }
}

.card-accordion {
  .card-header {
    a {
      color: inherit;
      text-decoration: none;
      font-size: 1rem;
      &:hover,
      &:focus {
        color: $brand-primary;
      }
    }
  }
  .card {
    .card-header {
      padding: 12px 24px;
    }
    .card-title a {
      text-decoration: none;
    }
  }
}

.card-default {
  // background-color: #fff;
  .card-header {
    background-color: rgba($gray-base, 0.075);
  }
}

.card-inverse {
  .text-muted {
    color: rgba(255, 255, 255, 0.5) !important;
  }
}

// Well & Jumbotron
// -----------------------------------

.well {
  border: 1px solid rgba($gray-base, 0.16);
  background-color: #fff;
  // box-shadow: $material-shadow-z1;
}

.jumbotron {
  padding: 1.5rem 3rem;
  border: 1px solid rgba($gray-base, 0.12);
  background-color: #fff;
  // box-shadow: $material-shadow-z1;
  h1 {
    font-size: 3.9375rem;
  }
  p {
    font-weight: 300;
    font-size: 1.3125rem;
    margin-bottom: 1rem;
  }
  @media #{$min-tablet} {
    padding: 1.875rem 3.75rem;
  }
}

// Nav - Tabs - Pills
// -----------------------------------
.nav-tabs {
  padding-bottom: 1px;
  border-bottom: 0;
}

// .nav-tabs > .nav-item {
//   // color: $text-color;
// }

.nav-tabs > .nav-item > .nav-link,
.nav-tabs.nav-justified > .nav-item > .nav-link {
  padding: 16px 24px;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  // border-bottom: 2px solid;
  color: inherit;
  &:hover,
  &:focus {
    border-bottom-color: rgba($gray-base, 0.36);
    background-color: transparent;
    color: inherit;
  }
}
.nav-tabs.nav-justified.nav-item > .nav-link {
  margin-bottom: 2px;
  border-bottom-color: rgba($gray-base, 0.16);
}

.nav-tabs > .nav-item > .nav-link {
  &.active,
  &.active:hover,
  &.active:focus {
    color: $brand-primary;
  }
}

.nav-tabs > .nav-item > .nav-link.active,
.nav-tabs > .nav-item > .nav-link.active:focus,
.nav-tabs > .nav-item > .nav-link.active:hover,
.nav-tabs.nav-justified > .nav-item > .active,
.nav-tabs.nav-justified > .nav-item > .active:hover,
.nav-tabs.nav-justified > .nav-item > .active:focus {
  border: 0;
  border-bottom: 2px solid;
  // color: inherit;
  background-color: transparent;
}
.tab-content {
  padding: 10px 20px;
}
.nav-pills > .nav-item > .nav-link.active,
.nav-pills > .nav-item > .nav-link.active:focus,
.nav-pills > .nav-item > .nav-link.active:hover {
  background-color: $brand-primary;
}
.nav-pills > .nav-item + .nav-item {
  margin-left: 5px;
}

.nav > .nav-item > .nav-link:focus,
.nav > .nav-item > .nav-link:hover {
  background-color: rgba($gray-base, 0.12);
}

// Forms & Inputs
// -----------------------------------

.form-control {
  height: calc(2rem + 2px);
  padding: 0.375rem 0.75rem;
  box-shadow: 0 0 0 #000 !important;
  font-size: inherit;
  &.form-control-lg {
    height: calc(2.875rem + 2px);
    padding: 0.5rem 1rem;
  }
  &:focus {
    // border-color: #aaa;
    border-color: #80bdff;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25) !important;
    outline: 0;
    background-color: #fff;
    color: #495057;
  }
}

.form-check-input {
  margin-right: 0.75rem;
}

.input-group-prepend,
.input-group-append,
.input-group-text,
.custom-file-label,
.custom-file-label::after {
  height: calc(2rem + 2px);
  font-size: inherit;
}

.custom-control-label {
  line-height: 1.75;
}

@media #{$max-desktop} {
  // Remove Input Shadows on iPad
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none;
  }
}

.has-success {
  @include form-validation-state(success, $brand-success);
}
.has-warning {
  @include form-validation-state(warning, $brand-warning);
}
.has-danger {
  @include form-validation-state(danger, $brand-danger);
}

.input-sm,
select.input-sm {
  height: 31px;
}

legend {
  border-bottom: 1px solid rgba($gray-base, 0.25);
  padding-bottom: 1rem;
}

// fieldset {
//   padding-bottom: 20px;
//   border-bottom: 1px dashed rgba($gray-base, 0.25);
//   margin-bottom: 20px;
//   &.last-child,
//   &:last-child {
//     border-bottom: 0;
//   }
//   .form-group {
//     margin-bottom: 0;
//   }
// }

@media #{$max-tablet} {
  // Remove Input Shadows on iPad
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none;
  }
}

// Tables
// -----------------------------------

.table {
  font-weight: 400;
  margin-bottom: 2rem;
  // Bottom align for column headings
  > thead > tr > th {
    border-bottom-width: 1px;
    border-color: rgba($gray-base, 0.16);
    position: relative;
    vertical-align: bottom;
    text-overflow: ellipsis;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0;
    height: 48px;
    font-size: 12px;
    // color: rgba(0,0,0,.54);
    padding: 0 18px 0 18px !important;
    padding-bottom: 8px !important;
  }
  > tbody > tr > td {
    position: relative;
    vertical-align: middle;
    height: 48px !important;
    // line-height: 36px !important;
    padding: 14px 18px;
    border-top: 1px solid rgba(0, 0, 0, 0.045);
  }
  // tbody {
  //   // color: $mdc-grey-600;
  // }
  // Account for multiple tbody instances
  > tbody + tbody {
    border-bottom-width: 1px;
  }
  td,
  th {
    border-top: 0;
  }
}

.table-hover > tbody > tr:hover {
  background-color: rgba($gray-base, 0.06);
}
.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: rgba($gray-base, 0.06);
}
.table-bordered {
  border-color: rgba($gray-base, 0.26);
  > thead > tr {
    > th,
    > td {
      border-bottom-width: 1px;
    }
  }
  > tbody > tr > td,
  > tbody > tr > th,
  > tfoot > tr > td,
  > tfoot > tr > th,
  > thead > tr > td,
  > thead > tr > th {
    border-color: rgba($gray-base, 0.26);
  }
}

.card > .table-bordered,
.card > .table-responsive > .table-bordered {
  border: 0;
}
.card .table-bordered td,
.card .table-bordered th {
  &:first-child {
    border-left: 0;
  }
  &:last-child {
    border-right: 0;
  }
}
.card > .table > tbody:first-child > tr:first-child td,
.card > .table > thead:first-child > tr:first-child th {
  border-top: 0;
}

// Generate the contextual variants
@include table-row-variant(active, $mdc-blue-grey-100);
@include table-row-variant(success, $brand-success);
@include table-row-variant(info, $brand-info);
@include table-row-variant(warning, $brand-warning);
@include table-row-variant(danger, $brand-danger);

// improve colorization
tr {
  &.active,
  &.success,
  &.info,
  &.warning,
  &.danger {
    color: rgba(0, 0, 0, 0.67);
    > td {
      border-top: 0 !important;
    }
  }
}

// Progress
// -----------------------------------

.progress {
  box-shadow: 0 0 0 #000;
  border-radius: $border-radius-small;
  border: 1px solid rgba(240, 240, 240, 0.1);
  background-color: rgba($gray-base, 0.26);
  height: 1.25rem;
  line-height: 1.25rem;
  font-size: inherit;
  .progress-bar {
    height: 100%;
    box-shadow: 0 0 0 #000;
    line-height: 1.4; // vertical align
  }

  // Different size of Progress bars
  &.progress-sm {
    height: $progress-height-sm;
  }

  &.progress-xs {
    height: $progress-height-xs;
    border-radius: 0;
    border: 0;
  }
}

.progress-bar {
  background-color: $brand-primary;
}
.progress-bar-info {
  background-color: $brand-info;
}
.progress-bar-success {
  background-color: $brand-success;
}
.progress-bar-warning {
  background-color: $brand-warning;
}
.progress-bar-danger {
  background-color: $brand-danger;
}

// Popover
// --------------------------------------------------

.popover {
  box-shadow: $material-shadow-z1;
  border-color: $gray-lighter;
  border-radius: $border-radius-small;
  color: $text-color;
  font-size: inherit;
  .popover-title {
    margin: 0;
    line-height: 1;
    font-size: 1.6rem;
    font-weight: 500;
  }
  .popover-header {
    margin: 0;
    line-height: 1.2;
  }
}

.tooltip {
  font-size: inherit;
}

// Carousel
// -----------------------------------

.carousel {
  .carousel-indicators {
    bottom: 0;
  }
  .carousel-control {
    &.left,
    &.right {
      background-image: none;
    }
    // Add support for icons
    em {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 5;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      margin-left: -10px;
    }
  }
}

// Dropdowns
// -----------------------------------

.dropdown-menu {
  border: 1px solid $gray-lighter;
  border-radius: 1px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  font-size: inherit;
  .dropdown-item {
    padding: 0 14px;
    line-height: 48px;
    display: block;
    color: $gray-darker;
    &:hover {
      background: $gray-lighter;
    }
    &.active {
      color: #fff;
      background-color: $primary;
    }
  }
  .dropdown-item > em {
    margin-right: 10px;
  }
  .dropdown-divider {
    margin: 0.5625rem 0;
  }
}

.dropdown-header {
  margin: 0;
  color: #a1a2a3;
  padding: 0 14px;
}

.navbar-default .navbar-nav .show .dropdown-menu > li > a {
  color: #666;
  &:hover,
  &:focus {
    color: #222;
  }
}

// fix for ie9 (dropdwon no showing)
.navbar,
.navbar .dropdown-menu {
  filter: none !important;
}

// Badges
// -----------------------------------

.badge {
  $sz: 20px;
  width: $sz;
  height: $sz;
  padding: 0;
  line-height: $sz;
  font-size: 12px;
  border-radius: 50%;
  color: #fff;
}

.has-badge {
  position: relative;
  .badge {
    position: absolute;
    top: 18%;
    right: 4px;
  }
  &.dropdown-toggle::after {
    display: none;
  }
}


.w85-m-0{
  width: 85%;
  margin: 0 auto;
}

.container-alert{
  margin: 0 auto;
  width: 300px;
  height: 300px;
}
.border-1px-solid > th,td{
  border:1px solid rgba(97, 96, 96, 0.349);
}
.th-table-arrow >span>svg{
  position: absolute;
  left: 100%;
}
